



































































































































import axios from 'axios';
import QrcodeVue from 'qrcode.vue';
import {
  Component, Vue,
} from 'vue-property-decorator';
import PlayerCard from '@/types/playerCard';

@Component({
  components: { QrcodeVue },
})
export default class Card extends Vue {
  private player = new PlayerCard();

  private profilepicture = '';

  private favPosition = '--';

  mounted(): void {
    axios.get(`${process.env.VUE_APP_FABINHO_URL}/allstarplayer/detail/`, {
      params: {
        id: this.$route.params.id,
      },
    }).then((response) => {
      if (response.data.results.length > 0) {
        [this.player] = response.data.results;
        axios.get(`${process.env.VUE_APP_OKOCHA_URL}/profile/allstar_identity/`, {
          params: {
            user_id: this.player.owner_okocha_id,
          },
        }).then((okochaResponse) => {
          if (okochaResponse.data.results.length > 0) {
            this.profilepicture = okochaResponse.data.results[0].card_photo;
            if (okochaResponse.data.results[0].fav_position) {
              this.favPosition = okochaResponse.data.results[0].fav_position;
            }
            if (okochaResponse.data.results[0].nickname) {
              this.player.name = okochaResponse.data.results[0].nickname;
            }
          }
        });
      }
    });
  }
}
